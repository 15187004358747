import React from 'react'
import styled from 'styled-components/macro'

export const BodyWrapper = styled.div<{ margin?: string; maxWidth?: string }>`
  background: linear-gradient(90deg, rgba(16, 16, 18, 0.8) 0%, rgba(51, 51, 51, 0.9) 50%, rgba(16, 16, 18, 0.8) 100%);
  border-radius: 8px;
  border: 1px solid rgba(251, 231, 198, 0.2);
  backdrop-filter: blur(4px) saturate(150%);
  box-shadow: rgba(251, 231, 198, 0.1) 0px 0px 10px, rgba(251, 231, 198, 0.1) 0px 0px 15px,
    rgba(251, 231, 198, 0.1) 0px 0px 20px, rgba(251, 231, 198, 0.1) 0px 0px 25px, rgba(251, 231, 198, 0.1) 0px 0px 30px,
    rgba(251, 231, 198, 0.1) 0px 0px 45px, rgba(251, 231, 198, 0.1) 0px 0px 40px;
  position: relative;
  margin-top: ${({ margin }) => margin ?? '0px'};
  max-width: ${({ maxWidth }) => maxWidth ?? '480px'};
  width: 100%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 480px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 380px) {
    max-width: 320px;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, ...rest }: { children: React.ReactNode }) {
  return <BodyWrapper {...rest}>{children}</BodyWrapper>
}
