import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import SettingsTab from '../Settings'
import { Percent } from '@uniswap/sdk-core'
import { CSSProperties } from 'react'
import { RowBetween, RowFixed } from '../Row'

const swapBanner: CSSProperties = {
  backgroundImage: `url(/images/swap-banner.jpg)`,
  width: '1000px',
  height: '600px',
  position: 'absolute',
  borderRadius: '8px',
  opacity: 0.4,
  top: '-260px',
  left: '-100px',
  transform: 'rotate(-15deg)',
  userSelect: 'none',
  backdropFilter: 'blur(20px) saturate(100%)',
}

const swapHeader: CSSProperties = {
  display: 'grid',
  gridAutoRows: 'auto',
  borderRadius: '8px',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  border: '1px solid #d8886788',
  whiteSpace: 'pre-line',
  backdropFilter: 'blur(4px) saturate(100%)',
  boxShadow:
    'rgba(251, 231, 198, 0.1) 0px 0px 10px, rgba(251, 231, 198, 0.1) 0px 0px 15px, rgba(251, 231, 198, 0.1) 0px 0px 20px, rgba(251, 231, 198, 0.1) 0px 0px 25px, rgba(251, 231, 198, 0.1) 0px 0px 30px, rgba(251, 231, 198, 0.1) 0px 0px 45px, rgba(251, 231, 198, 0.1) 0px 0px 40px',
}

const swapHeaderText: CSSProperties = {
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0,
  fontWeight: 600,
  color: 'white',
  fontSize: '18px',
}

const swapHeaderContainer: CSSProperties = {
  display: 'grid',
  gridAutoRows: 'auto',
  padding: '1rem',
  zIndex: 1,
  backdropFilter: 'blur(3px) saturate(350%) brightness(30%) grayscale(40%)',
  borderRadius: '8px',
}

const swapHeaderDescription: CSSProperties = {
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0,
  width: '100%',
  padding: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
}

const StyledSwapHeader = styled.div`
  padding: 0px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  color: white;
`

const swapInputFieldsHeader: CSSProperties = {
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0,
  fontWeight: 500,
  fontSize: '24px',
  color: 'white',
}

const swapInputFieldsHeaderContainer: CSSProperties = {
  padding: '1rem 1.25rem 0.5rem',
  width: '100%',
  color: 'rgb(195, 197, 203)',
}

const swapIcon: CSSProperties = {
  display: 'none',
  maxWidth: '40px',
  maxHeight: '40px',
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  verticalAlign: 'sub',
  marginLeft: '-10px',
  marginBottom: '-5px',
}

export default function SwapHeader({ allowedSlippage }: { allowedSlippage: Percent }) {
  return (
    <StyledSwapHeader>
      <div style={swapHeader}>
        <div style={swapHeaderContainer}>
          <div style={swapHeaderText}>Exchange ETN Network Tokens</div>
          <div style={swapHeaderDescription}>
            {/* <p>Working hard to dig up what you need...</p> */}
            <p>NOTE: On Popup.  Make sure to lower the MAX TRANSFER amount in wallet to an amount you are comfortable with. Uniswap inserts a large Token Amount.</p>
          </div>
        </div>
        <div style={swapBanner} />
      </div>

      <div style={swapInputFieldsHeaderContainer}>
        <RowBetween>
          <RowFixed>
            <div style={swapInputFieldsHeader}>
              <img src="/images/dog.png" style={swapIcon} alt="" />
              <Trans>Swap</Trans>
            </div>
          </RowFixed>
          <RowFixed>
            <SettingsTab placeholderSlippage={allowedSlippage} />
          </RowFixed>
        </RowBetween>
      </div>
    </StyledSwapHeader>
  )
}
