import { FACTORY_ADDRESS as V2_FACTORY_ADDRESS } from '@uniswap/v2-sdk'
import { FACTORY_ADDRESS as V3_FACTORY_ADDRESS } from '@uniswap/v3-sdk'
import { constructSameAddressMap } from '../utils/constructSameAddressMap'
import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const UNI_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'),
  [SupportedChainId.ELECTRONEUM]: '0x44B3CD8ed402bd3446Ee798Ac01F2e15ca0961dE',
}
export const MULTICALL_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x9fF674871d50d7B4f3E85E3E129096B0339eb3a5', [SupportedChainId.ELECTRONEUM]),
  [SupportedChainId.OPTIMISM]: '0x90f872b3d8f33f305e0250db6A2761B354f7710A',
  [SupportedChainId.ARBITRUM_ONE]: '0xadF885960B47eA2CD9B55E6DAc6B42b7Cb2806dB',
  [SupportedChainId.ARBITRUM_RINKEBY]: '0xa501c031958F579dB7676fF1CE78AD305794d579',
  [SupportedChainId.ELECTRONEUM]: '0x9fF674871d50d7B4f3E85E3E129096B0339eb3a5',
}
export const V2_FACTORY_ADDRESSES: AddressMap = V2_FACTORY_ADDRESS

export const V2_ROUTER_ADDRESS: AddressMap = {
  ...constructSameAddressMap('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'),
  [SupportedChainId.ELECTRONEUM]: '0xA076229ef728E5C3dfE665E04E6994496F748835',
}
/**
 * The older V0 governance account
 */
export const GOVERNANCE_ALPHA_V0_ADDRESSES: AddressMap = constructSameAddressMap(
  '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'
)
/**
 * The latest governor alpha that is currently admin of timelock
 */
export const GOVERNANCE_ALPHA_V1_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0xC4e172459f1E7939D522503B81AFAaC1014CE6F6',
}

export const TIMELOCK_ADDRESS: AddressMap = constructSameAddressMap('0x1a9C8182C09F50C8318d769245beA52c32BE35BC')

export const MERKLE_DISTRIBUTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e',
}
export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xeca4B0bDBf7c55E9b7925919d03CbF8Dc82537E8',
}
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...constructSameAddressMap(V3_FACTORY_ADDRESS, [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
  ]),
  [SupportedChainId.ELECTRONEUM]: '0xE5Aab8DaE65FaB1221edB4E31fd47A8743ed87A7',
}
export const QUOTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xf15E8e272d6588Ff5c365885e7B4A3e43Da01114', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
  ]),
  [SupportedChainId.ELECTRONEUM]: '0xf15E8e272d6588Ff5c365885e7B4A3e43Da01114',
}
export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x65ab6A9bd9Dfe5F4672168aD99f4c2Bc13405b96', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
  ]),
  [SupportedChainId.ELECTRONEUM]: '0x65ab6A9bd9Dfe5F4672168aD99f4c2Bc13405b96',
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  [SupportedChainId.ROPSTEN]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.GOERLI]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
  [SupportedChainId.RINKEBY]: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
}
export const SOCKS_CONTROLLER_ADDRESSES: AddressMap = {
  [SupportedChainId.MAINNET]: '0x65770b5283117639760beA3F867b69b3697a91dd',
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0xeC327943e92b1E2F7900a9D071F77FA8D4755fFF', [
    SupportedChainId.OPTIMISM,
    SupportedChainId.OPTIMISTIC_KOVAN,
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
  ]),
  [SupportedChainId.ELECTRONEUM]: '0xeC327943e92b1E2F7900a9D071F77FA8D4755fFF',
}
export const V3_MIGRATOR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x48c7388650a6A1Dd5fe9F40CF86f04eCf9699D45', [
    SupportedChainId.ARBITRUM_ONE,
    SupportedChainId.ARBITRUM_RINKEBY,
  ]),
  [SupportedChainId.ELECTRONEUM]: '0x48c7388650a6A1Dd5fe9F40CF86f04eCf9699D45',
}
